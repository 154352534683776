.App {
  text-align: center;
  margin: 0 auto;
}

a {
  text-decoration: unset !important;
  color: #f50057;
}

a:hover {
  color: purple;
}

p {
  font-family: "Poppins";
  font-weight: 300;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.main-square {
  /* margin-top: 5rem;
  position: relative; */
  display: flex;
  align-items: center;
  width: 300px;
  height: 300px;
  border: 0.25rem solid black;
}

.fitness-habit {
  font-size: 2rem;
}
.fitness-habit:hover {
  color: orange;
}
.fitness-habit-enabled {
  color: orange;
}
.book-habit {
  font-size: 2rem;
}
.book-habit-enabled {
  color: teal;
}
.book-habit:hover {
  color: teal;
}

.drinking-habit {
  font-size: 2rem;
}
.drinking-habit:hover {
  color: green;
}
.drinking-habit-enabled {
  color: green;
}

.music-habit {
  font-size: 2rem;
}
.music-habit:hover {
  color: red;
}
.music-habit-enabled {
  color: red;
}

.wakeup-time {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: royalblue;
  bottom: 2rem;
  right: -7rem;
}
.wakeup-time-text {
  font-size: 1rem;
  margin-left: 0.25rem;
}

.sleep-hours {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: royalblue;
  bottom: 0rem;
  right: -8rem;
}
.sleep-hours-text {
  font-size: 1rem;
  margin-left: 0.25rem;
}
.day-summary {
  font-size: 1rem;
  padding: 1rem;
  transition-property: font-size;
  transition-duration: 0.3s;
}

.day-summary:hover {
  font-size: 2rem;
  transition-property: font-size;
  transition-duration: 0.3s;
  padding: 1rem;
}

.textButtonOrange {
  font-weight: bold;
  color: #fc721e;
}
.textButtonTeal {
  font-weight: bold;
  color: #00c36b;
}

.square-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 300px;
}

.bottom-section {
  height: 30%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
